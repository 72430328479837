.news-detail__gallery {
    margin: 30px 0;
}

.news-detail__copy {
    a {
        text-decoration: underline;
    }
}

.news-detail__back-button {
    margin-bottom: 16px;
}

@include media-breakpoint-down(sm) {
    .news-detail__gallery {
        position: relative;
        left: 50%;
        right: 50%;

        width: 100vw;
        margin-left: -50vw;
        margin-right: -50vw;
        padding-right: 16px;
    }
}

@include media-breakpoint-up(md) {
    .news-detail {
        .overline {
            margin-bottom: 10px;
        }
    }

    .news-detail__gallery {
        margin: 70px 0 60px 0;
    }

    .news-detail__back-button {
        margin-bottom: 42px;
    }

    .news-detail__teaser {
        margin-bottom: 30px !important;
    }
}
