.locations {
    .teaser__image-wrapper {
        margin-top: 0;
    }

    .teaser__copy-wrapper {
        max-width: none;
    }

    .copy {
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
    }

    .google-maps__control,
    .google-maps .google-maps__control.control--fullscreen {
        display: none;
    }
}

.location__section-list-item {
    margin-top: 2px;
}

.location__section-list {
    margin-top: 10px;
}

.location__section {
    margin-top: 30px;

    &:first-of-type {
        margin-top: 0;
    }
}

@include media-breakpoint-down(sm) {
    .locations {
        .teaser__map-wrapper {
            margin-top: 20px;
        }
    }
}

@include media-breakpoint-up(md) {
    .location__section-list {
        margin-top: 20px;
    }

    .location__section {
        margin-top: 40px;
    }
}

@include media-breakpoint-up(xl) {
    .location__section-list {
        margin-top: 30px;
    }

    .location__section {
        margin-top: 60px;
    }
}