.icon-navigation {
    display: flex;
    margin: -10px;

    font-size: 34px;
}

.icon-navigation__item {
    display: block;

    padding: 10px;

    cursor: pointer;
}
