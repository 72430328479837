.separator {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;

    &--has-hover:hover {
        cursor: pointer;

        .separator__line {
            background-color: $color-gray-500;
        }

        .separator__btn .icon-btn {
            background-color: $color-gray-500;
        }
    }
}

.separator__line {
    margin: 20px 0;
    height: 3px;

    transition: background-color $animation-speed * 2;
}

.separator__btn {
    position: relative;
    top: -25px;
}

@include media-breakpoint-up(md) {
    .separator__line {
        margin: 0;
    }
}
