.cookie-list {
    font-weight: 300;
}

.cookie-list__entry {
    display: block;
}

.cookie-list__entry-name {
    font-weight: 700;
}

@include media-breakpoint-up(lg) {
    .cookie-list__entry {
        display: inline-block;

        &:last-child::after {
            content: "";
        }

        &::after {
            display: inline-block;

            margin-left: -4px;

            content: ",";
        }
    }

    .cookie-list__entry-name {
        font-weight: inherit;
    }
}
