.breadcrumb {
    display: none;
    position: fixed;
    background-color: fade_out($color-white, 0.05);
    border-radius: 0;
    padding: 0;
    box-shadow: 1px 1px 1px fade_out($color-gray-100, 0.05);
}

.breadcrumb__items {
    padding: 0;
    margin: 0 -10px;
    display: flex;
    z-index: 50;
}

.breadcrumb__item {
    display: inline-flex;
    align-items: center;

    &:after {
        content: $icon-arrow-right-big;
        font-family: 'verseidag-website-2020-iconfont' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    &:last-of-type {
        font-weight: $font-weight-bold;

        &:after {
            display: none;
        }
    }
}

.breadcrumb__link {
    padding: 10px;
    color: inherit;
}

@include media-breakpoint-up(lg) {
    .breadcrumb {
        width: 100%;
        display: block;
        top: 80px;
        z-index: 20;

        transition: width $animation-speed, background-color $animation-speed, top $animation-speed;
    }

    .site-wrapper.site-wrapper--header-fade-out {
        .breadcrumb {
            top: 0 !important;
        }
    }
}

@include media-breakpoint-up(xl) {
    .breadcrumb {
        top: 100px;
    }
}
