.address .address__part {
    display: block;
}

@include media-breakpoint-up(lg) {
    .address {
        .address__part--street,
        .address__part--phone {
            display: inline;

            &::after {
                display: inline;

                content: " |";
            }
        }

        .address__part--city,
        .address__part--mail {
            display: inline;

            &::after {
                display: block;

                content: "";
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .address .address__part {
        display: block;

        &::after {
            display: none;
        }
    }
}
