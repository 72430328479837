.content-main {
  padding-top: 104px;

  .content-element {
    padding-top: 20px;
    padding-bottom: 20px;

    &--first-of-color {
      padding-top: 30px;
    }

    &--last-of-color {
      padding-bottom: 30px;
    }

    &.separator {
      padding-top: 40px;
      padding-bottom: 60px;
    }
  }
}

.content-head:not(:empty) + .content-main {
  padding-top: 0;
}

.expander {
  z-index: 2;
}

@include media-breakpoint-down(sm) {
  .content-main {
    .content-element {
      &.brick-03 {
        padding-bottom: 0;
      }

      &.brick-03.content-element--color-sequence {
        &:after {
          content: '';
          display: block;
          height: 3px;
          width: 100%;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .content-main {
    .content-element {
      padding-top: 40px;
      padding-bottom: 40px;

      &--first-of-color {
        padding-top: 60px;

        &.brick-06 {
          padding-top: 0;
        }
      }

      &--last-of-color {
        padding-bottom: 60px;
      }

      &.separator {
        padding-top: 60px;
        padding-bottom: 80px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .content-main {
    padding-top: 124px;
  }
}

@include media-breakpoint-up(xl) {
  .content-main {
    .content-element {
      padding-top: 50px;
      padding-bottom: 50px;

      &--first-of-color {
        padding-top: 100px;
      }

      &--last-of-color {
        padding-bottom: 100px;
      }

      &.separator {
        padding-top: 120px;
        padding-bottom: 140px;
      }
    }
  }
}
