.teaser__container {
    padding: 25px;
}

.copyright {
    color: $color-gray-600;
    font-style: italic;
}

.line {
    height: 5px;
    margin: 10px 0;
}

.teaser__image-wrapper {
    margin: 19px 0 0;
}

.teaser__image {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.teaser__copy-wrapper {
    margin: 10px 0;
}

.teaser__date {
    margin-top: 8px;
    font-size: 15px;
}

.teaser__button-extension {
    display: block;
    width: 100%;

    text-align: center;
}

.teaser__subline-wrapper {
    max-width: 350px;
}

@include media-breakpoint-up(md) {
    .teaser__button-extension {
        display: inline-block;
        width: auto;
    }

    .teaser__container {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .line {
        height: 10px;
        margin: 40px 0;
    }

    .teaser__image-wrapper {
        margin: 38px 0 0;
    }

    .teaser__copy-wrapper {
        max-width: 684px;

        margin: 16px 0;
    }

    .teaser__image-wrapper figcaption {
        margin: 6px 0;
    }

    .teaser__subline-wrapper {
        max-width: 510px;
    }
}

@include media-breakpoint-up(lg) {
    .teaser__container {
        padding: 50px;
    }
}


