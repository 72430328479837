.footer {
    border-top: 1px solid $color-white;

    color: $color-white;

    background: $color-gray-900;
}

.footer__container {
    padding: 0;

    .footer__info {
        padding: 20px;
    }
}

.footer__nav {
    padding-top: 10px;

    .accordion {
        border-bottom: 2px solid $color-white;
    }

    .accordion__content {
        margin: 0;
        padding: 0;

        background-color: $color-gray-800;
    }
}

.footer__contact {
    margin-top: 15px;

    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

.footer__address .address__part {
    opacity: 0.8;
}

.footer__social {
    margin-top: 10px;

    .icon-navigation__item {
        font-size: 42px;
        line-height: 42px;

        opacity: 0.8;

        transition: $animation-speed ease opacity;

        &:hover {
            opacity: 1;
        }
    }
}

@include media-breakpoint-up(lg) {
    .footer {
        padding: 0 30px;
    }

    .footer__info {
        padding: $grid-gutter-width / 2;
    }

    .footer__container {
        .footer__info {
            display: flex;
            padding: 30px 0;
        }
    }

    .footer__contact {
        width: 100%;
        margin-top: 0;
        padding-left: 75px;
    }

    .footer__social {
        margin-top: 20px;
    }
}

@include media-breakpoint-up(xl) {
    .footer {
        padding: 30px;
    }

    .footer__container {
        padding: $grid-gutter-width / 2;

        .row {
            flex-direction: row-reverse;
        }

        .footer__info {
            display: block;
            width: 430px;
        }
    }

    .footer__nav {
        width: calc(100% - 430px);
        padding: 20px 0 0 30px;

        .accordion {
            border-bottom: 0;
        }

        .accordion__headline {
            display: inline-block;
            padding: 15px 30px 5px 0;
            border-bottom: 2px solid $color-white;

            font-weight: 500;
            font-size: 24px;

            cursor: initial;

            pointer-events: none;

            i {
                display: none;
            }
        }

        .accordion__content {
            display: block;
            padding-top: 8px;

            background-color: transparent;
        }

        .link-list__item {
            a {
                opacity: 0.8;

                transition: $animation-speed ease opacity;
            }

            &::after {
                opacity: 0.8;

                transition: $animation-speed ease opacity;
            }

            &:hover a {
                opacity: 1;
            }
        }
    }

    .footer__contact {
        margin-top: 18px;
        padding-left: 0;
    }

    .footer__social .icon-navigation__item {
        font-size: 33px;
        line-height: 33px;
    }
}
