$color-white: #fff;

$color-gray-100: #eaeaea;
$color-gray-200: #d4d4d3;
$color-gray-300: #c5c6c7;
$color-gray-400: #bcbcbd;
$color-gray-500: #b4b4b4;
$color-gray-550: #b3b2b3;
$color-gray-600: #9a9a9a;
$color-gray-700: #959ea0;
$color-gray-750: #949494;
$color-gray-800: #939293;
$color-gray-900: #6f6f6e;
$color-gray-925: #4c4c4c;
$color-gray-950: #333;

$color-black: #000;
$color-black-200: #050607;

$color-blue-100: #212043;
$color-blue-200: #211c45;
$color-blue-300: #004871;
$color-blue-400: #0477b9;

$color-red-100: #b61924;
$color-red-150: #845458;
$color-red-200: #600f14;

$color-green-100: #007b56;

$color-yellow-100: #edd59f;
$color-yellow-200: #fddd0e;
