
.placecard {
    display: flex;

    max-width: 350px;

    color: $color-gray-950;
    font-size: 12px;

    background: $color-white;
}

.placecard__address {
    width: calc(100% - 115px);

    padding: 15px 13px;
}

.address__headline {
    font-weight: 700;
}

.address__subline {
    margin-top: 4px;
}

.address__link {
    margin-top: 14px;

    .link-icon__text {
        padding: 0 2px 0 0;
    }

    .link-icon__icon {
        font-size: 12px;
    }
}

.placecard__directions {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 115px;

    border-left: 1px solid $color-gray-900;

    text-align: center;
}

.directions__link {
    color: $color-gray-950;
    font-weight: 400;
    font-size: 12px;

    img {
        display: block;

        margin: 0 auto 5px;
    }
}

@include media-breakpoint-up(xl) {
    .address__headline,
    .address__link,
    .directions__link {
        font-size: 14px;
    }
}
