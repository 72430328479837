.subline {
    font-weight: $font-weight-medium;
    font-size: 20px;
    font-family: $font-family-base;
    line-height: 29px;
}

@include media-breakpoint-up(xl) {
    .subline {
        font-size: 24px;
    }
}
