.error-page__image {
    padding-top: 50px;
    padding-bottom: 157px;

    text-align: center;
}

.error-page__image img {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
}

@include media-breakpoint-up(md) {
    .error-page__image {
        padding-top: 40px;
        padding-right: 77px;
    }

    .error-page__image img {
        width: 100%;
        padding-right: 30px;
        padding-left: 30px;
    }
}

@include media-breakpoint-up(xl) {
    .error-page__image img {
        padding: 0px;
        width: 90%;
        margin-left: 80px;
    }
}
