.image-teaser {
    width: 100%;

    overflow: hidden;

    &.image-teaser--passive-effect {
        position: relative;
        z-index: 1;

        filter: grayscale(100%);

        transition: filter $animation-speed * 2;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;

            display: block;

            background: linear-gradient(fade_out($color-white, 0.55), fade_out($color-white, 0.19));

            opacity: 1;

            transition: opacity $animation-speed * 2;

            content: "";
        }

        &:hover {
            filter: grayscale(0);

            &::after {
                opacity: 0;
            }

            .image-teaser__label {
                color: $color-white;

                background-color: fade_out($color-black, 0.4);
            }
        }
    }
}

.image-teaser__label {
    position: absolute;
    bottom: 0;
    z-index: 3;

    width: 100%;
    padding: 20px 30px;

    font-size: 20px;

    background-color: transparent;

    transition: background-color $animation-speed * 2, color $animation-speed * 2;
}

