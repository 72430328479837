.search-layer {
  position: absolute;
  z-index: 9999;
  top: -100vw;
  right: 0;
  width: 0;
  height: 100vh;
  overflow-y: auto;
  transition: right $animation-speed * 2, width $animation-speed * 2;
  background-color: fade_out($color-black, 0.43);

  &.is-active {
    top: 0;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    transition: top $animation-speed, width $animation-speed;
  }
}

.search-layer-content {
  position: relative;
  top: 100px;
  z-index: 1;
  max-width: 540px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.search-field-wrapper {
  overflow: hidden;
  opacity: 0;
  width: 100%;

  &.is-active {
    opacity: 1;
  }
}

.search-form {
  position: relative;
  padding: 0;

  display: flex;
}

.search-field {
  margin: 0;
  color: $color-white;
  background-color: $color-gray-500;
  outline: none;
  height: 50px;
  font-size: 18px;
  font-family: $font-family-base;
  line-height: 18px;
  padding: 12px 15px;
  border: none;
  width: calc(100% - 70px);

  &::placeholder {
    color: $color-white;
    line-height: 18px;
    height: 50px;
    font-size: 18px;
  }

  &:focus {
    color: $color-black-200;
    background-color: $color-white;
    border: none;
    outline: none;
  }
}

.search-btn {
  height: 50px;
  width: 50px;
  margin-left: 10px;

  border: none;

  i {
    cursor: pointer;
    font-size: 28px;
  }

  .btn__text {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 12px;
  }
}

.autocomplete-suggestions,
.search-suggestions {
  width: 100%;
  font-size: 16px;
  font-family: $font-family-base;
  opacity: 0;
  transform: translateY(-0.625rem);
  overflow: hidden;
  transition: max-height $animation-speed, opacity $animation-speed, transform $animation-speed, padding $animation-speed;
  max-height: none !important;

  &.autocomplete-suggestions,
  &.is-active {
    opacity: 1;
    transform: translateY(0);

    &:empty {
      opacity: 0;
    }
  }
}

.autocomplete-suggestion,
.search-suggestion-item {
  color: $color-white;
  background-color: $color-gray-500;
  height: 40px;
  font-size: 18px;
  font-family: $font-family-base;
  line-height: 18px;
  padding: 12px 15px;
  width: calc(100% - 70px);
  margin: 2px 0;
  display: block;
  cursor: pointer;

  &:hover {
    color: $color-gray-925;
  }

  &.autocomplete-suggestion {
    width: 100%;
  }
}

@include media-breakpoint-up(sm) {
  .search-layer-content {
    max-width: 540px;
  }

  .search-field-wrapper {
    padding: 0 15px;
  }
}

@include media-breakpoint-up(md) {
  .search-layer-content {
    max-width: 720px;
  }
}

@include media-breakpoint-up(lg) {
  .search-layer-content {
    max-width: 960px;
  }
}

@include media-breakpoint-up(xl) {
  .search-layer-content {
    padding: 0;
    max-width: 1600px;
    top: 144px;
  }

  .search-field-wrapper {
    padding: 0;
  }

  .search-field {
    overflow: hidden;
    opacity: 0;
    width: calc(100% - 64px);

    transition: max-width $animation-speed, opacity $animation-speed;

    &.is-active {
      opacity: 1;
    }
  }

  .search-suggestion-item {
    width: calc(100% - 64px);
  }
}
