.modal__container {
    position: absolute;
    top: 50%;
    left: 50%;

    padding: 25px 30px;

    color: $color-white;

    background: rgba(0, 0, 0, 0.9);

    transform: translate(-50%, -50%);
}

.modal__close {
    position: absolute;
    top: 17px;
    right: 20px;

    border: 0;

    color: $color-white;
    font-size: 28px;

    background: transparent;
}
