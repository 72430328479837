.video-container {
    position: relative;

    height: 0;
    padding-bottom: 56.25%;

    overflow: hidden;

    background-color: $color-black;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
}
