.cookie-layer {
    font-size: 16px;
    line-height: 26px;

    .headline {
        font-size: 20px;
        line-height: 30px;
    }

    .copy {
        margin-top: 5px;

        line-height: 26px;
    }

    a {
        display: block;

        font-weight: 300;

        text-decoration: underline;
    }
}

.cookie-layer__homepage {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;

    color: $color-white;

    transform: translateY(100%);

    transition: $animation-speed ease transform;

    &.cookie-layer__homepage--open {
        transform: translateY(0);
    }

    .container-xl {
        padding: 25px;

        background: rgba(0, 0, 0, 0.9);
    }

    .cookie-layer__cookie-list {
        margin-top: 23px;
    }

    .cookie-layer__actions {
        margin-top: 8px;
    }

    .cookie-layer__btn-accept-all {
        margin-top: 16px;
    }
}

.cookie-layer__settings {
    display: block;

    opacity: 0;

    transition: $animation-speed ease opacity;

    pointer-events: none;

    &.cookie-layer__settings--open {
        opacity: 1;

        pointer-events: initial;
    }

    .cookie-layer__actions {
        margin-top: 20px;
    }

    .cookie-layer__btn-accept-all {
        margin-top: 10px;
    }
}

.cookie-layer__options {
    margin-top: 12px;

    .row {
        border-bottom: 1px solid $color-white;

        &:last-child {
            border-bottom: 0;
        }
    }

    .col {
        padding-top: 12px;
        padding-bottom: 12px;

        &:first-child {
            font-weight: 700;
            font-size: 18px;
            line-height: 30px;
        }

        &:last-child {
            flex-grow: unset;
        }
    }

    .toggle {
        width: 100px;
    }
}

.cookie-layer__actions {
    .btn__wrapper {
        display: block;
        width: 100%;

        text-align: center;
    }
}

.cookie-layer__btn-accept-necessary {
    margin-top: 4px;
}

@include media-breakpoint-up(lg) {
    .cookie-layer__homepage {
        .container-xl {
            padding: 25px 50px;
        }

        .copy a {
            display: inline;
        }

        .cookie-layer__actions {
            display: flex;
            align-items: center;
            margin-top: 20px;

            a {
                font-size: 18px;
                line-height: 30px;
            }
        }

        .cookie-layer__btn-accept-necessary {
            margin: 0 0 0 20px;
        }

        .cookie-layer__btn-accept-all {
            width: auto;
            margin: 0 0 0 20px;
        }
    }

    .cookie-layer__settings {
        .cookie-layer__actions {
            display: flex;
            justify-content: space-between;

            .btn__wrapper {
                display: inline-block;
                width: auto;
            }
        }

        .cookie-layer__btn-accept-all {
            margin-top: 0;
        }
    }

    .cookie-layer__options {
        margin-top: 0;
    }
}
