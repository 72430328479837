.image {
    img {
        width: 100%;
    }
}

.image--blur-up {
    filter: blur(10px);

    transition: filter $animation-speed;

    &.lazyloaded {
        filter: blur(0);
    }
}

.image__text {
    margin-top: 5px;
}

.image__copyright {
    color: $color-gray-600;
    font-style: italic;
}
