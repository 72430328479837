.child-listing-grid {
    padding: 5px;
}

.image-teaser__image {
    width: 100%;
}

.image-teaser__label {
    width: 100%;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

@include media-breakpoint-up(xs) {
    .image-teaser__label {
        padding: 10px 5px;

        font-size: 16px;
    }
}

@include media-breakpoint-up(sm) {
    .image-teaser__label {
        padding: 20px 30px;
    }
}

@include media-breakpoint-up(md) {
    .col-md-2 {
        flex: 0 0 20%;
        max-width: 20%;
    }
    
    .image-teaser__label {
        padding: 0 10px;
    }
}

@include media-breakpoint-up(lg) {
    .image-teaser__label {
        padding: 10px 20px;

        font-size: 20px;
    }
}
