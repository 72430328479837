.service-badge {
    position: fixed;
    top: 100vh;
    right: 0;
    z-index: 2000;

    display: flex;
    flex-direction: column;
    width: 100%;

    color: $color-white;

    transform: translateY(0);

    transition: $animation-speed ease transform;

    &.service-badge--open {
        transform: translateY(-100%);
    }
}

.service-badge__trigger {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2000;

    display: flex;
    align-self: flex-end;
    width: 118px;

    color: $color-white;

    font-size: 32px;

    background: rgba(0, 0, 0, 0.85);

    transform: translateY(-48px);

    cursor: pointer;
}

.service-badge__link {
    width: 58px;
    padding: 10px;

    text-align: center;

    &:first-child {
        width: 60px;

        border-right: 2px solid $color-white;
    }

    i {
        display: block;

        font-size: 28px;
    }
}

.service-badge__content {
    padding: 20px 30px 30px;

    background: rgba(0, 0, 0, 0.85);
}

.service-badge__headline {
    font-weight: 500;
    font-size: 24px;
}

.service-badge__copy,
.service-badge__phone,
.service-badge__mail,
.service-badge__opening {
    display: block;

    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
}

.service-badge__copy {
    margin-top: 10px;
}

.service-badge__phone {
    margin-top: 15px;
}

.service-badge__actions {
    margin-top: 30px;

    .btn__wrapper {
        display: block;
        width: 100%;

        text-align: center;

        &:last-child {
            margin-top: 15px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .service-badge {
        top: 150px;
        right: 0;

        flex-direction: row;
        width: auto;

        transform: translateX(100%) translateY(0);

        &.service-badge--open {
            transform: translateX(0);
        }
    }

    .service-badge__trigger {
        top: 0;
        left: -70px;

        transform: translateX(0) translateY(0);

        flex-direction: column;
        align-self: flex-start;
        width: 70px;
    }

    .service-badge__link {
        width: 70px;

        &:first-child {
            width: 70px;

            border-right: 0;
            border-bottom: 2px solid $color-white;
        }

        i {
            font-size: 38px;
            line-height: 52px;
        }
    }

    .service-badge__content {
        width: 530px;
        max-width: calc(100vw - 70px);
        padding: 30px 40px 40px;
    }

    .service-badge__actions {
        .btn__wrapper {
            width: 240px;

            &:last-child {
                display: none;
            }
        }
    }
}

@media screen and (min-width: map-get($grid-breakpoints, xl)) and (max-width: 1450px) {
    .service-badge {
        top: 50%;
        right: 0;

        transform: translateX(100%) translateY(-50%);

        &.service-badge--open {
            transform: translateY(-50%) translateX(70px);
        }
    }

    .service-badge__trigger {
        top: 50%;
        left: -70px;

        transform: translateX(0) translateY(-50%);
    }
}
