.list {
    font-weight: $font-weight-normal;
    font-size: 16px;
    font-family: $font-family-base;
    line-height: 24px;
}

@include media-breakpoint-up(xl) {
    .list {
        font-size: 18px;
    }
}
