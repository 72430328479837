.beam {
    display: block;
    height: 5px;
    margin-bottom: 13px;
}

@include media-breakpoint-up(md) {
    .beam {
        height: 10px;
        margin-bottom: 34px;
    }
}

@include media-breakpoint-up(xl) {
    .beam {
        margin-bottom: 45px;
    }
}
