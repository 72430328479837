.toggle {
    position: relative;

    input {
        position: absolute;
        bottom: 0;
        z-index: 1;

        width: 74px;
        height: 35px;

        cursor: pointer;
        opacity: 0;
    }

    input:checked + .toggle__state {
        .toggle__label--checked {
            display: inline;
        }

        .toggle__label--unchecked {
            display: none;
        }

        &::after {
            left: 41px;

            background-color: #67bc12;
        }
    }

    input:disabled {
        cursor: default;

        + .toggle__state::after {
            background-color: $color-gray-800;
        }
    }

    .toggle__state {
        position: relative;

        min-width: 74px;

        margin: 0;
        padding-bottom: 38px;

        &::before {
            position: absolute;
            bottom: 0;
            left: 0;

            width: 74px;
            height: 35px;

            border-radius: 18px;

            transition: $animation-speed ease all;

            content: "";
        }

        &::after {
            position: absolute;
            bottom: 5px;
            left: 5px;

            width: 25px;
            height: 25px;

            border-radius: 25px;

            background-color: $color-red-200;

            transition: $animation-speed ease all;

            content: "";
        }

        .toggle__label--checked {
            display: none;
        }
    }
}
