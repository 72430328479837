@font-face {
    font-family: "Fira Sans";
    font-display: block;
    src: url("#{$font-path}fira-sans/FiraSans-Regular.woff2"),
         url("#{$font-path}fira-sans/FiraSans-Regular.woff");
}

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-display: swap;

    src: url("#{$font-path}fira-sans/FiraSans-Italic.woff2"),
         url("#{$font-path}fira-sans/FiraSans-Italic.woff");
}

@font-face {
    font-weight: $font-weight-light;
    font-family: "Fira Sans";
    font-display: swap;

    src: url("#{$font-path}fira-sans/FiraSans-Light.woff2"),
         url("#{$font-path}fira-sans/FiraSans-Light.woff");
}

@font-face {
    font-weight: $font-weight-light;
    font-family: "Fira Sans";
    font-style: italic;
    font-display: swap;

    src: url("#{$font-path}fira-sans/FiraSans-LightItalic.woff2"),
         url("#{$font-path}fira-sans/FiraSans-LightItalic.woff");
}

@font-face {
    font-weight: $font-weight-medium;
    font-family: "Fira Sans";
    font-display: swap;

    src: url("#{$font-path}fira-sans/FiraSans-Medium.woff2"),
         url("#{$font-path}fira-sans/FiraSans-Medium.woff");
}

@font-face {
    font-weight: $font-weight-medium;
    font-family: "Fira Sans";
    font-style: italic;
    font-display: swap;

    src: url("#{$font-path}fira-sans/FiraSans-MediumItalic.woff2"),
         url("#{$font-path}fira-sans/FiraSans-MediumItalic.woff");
}

@font-face {
    font-weight: $font-weight-bold;
    font-family: "Fira Sans";
    font-display: swap;

    src: url("#{$font-path}fira-sans/FiraSans-Bold.woff2"),
         url("#{$font-path}fira-sans/FiraSans-Bold.woff");
}

@font-face {
    font-weight: $font-weight-bold;
    font-family: "Fira Sans";
    font-style: italic;
    font-display: swap;

    src: url("#{$font-path}fira-sans/FiraSans-BoldItalic.woff2"),
         url("#{$font-path}fira-sans/FiraSans-BoldItalic.woff");

}

.fira-sans {
    &--italic {
        font-style: italic;
    }

    &--light {
        font-weight: $font-weight-light;
    }

    &--medium {
        font-weight: $font-weight-medium;
    }

    &--bold {
        font-weight: $font-weight-bold;
    }
}
