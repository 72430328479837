.light-theme,
.dark-theme .light-theme{
    color: $color-black;

    &.beam,
    .beam {
        background-color: $color-gray-900;
    }

    .brick-03-media__teaser-container .teaser__headline {
        color: #fff;
    }

    &.checkbox,
    .checkbox {
        &:hover .checkbox__state {
            color: $color-black-200;
        }

        &.checkbox--error {
            .checkbox__state {
                color: $color-red-200;

                &::before {
                    background-color: $color-red-150;
                }
            }
        }

        &.checkbox--disabled .checkbox__state {
            color: $color-gray-550;
        }

        .checkbox__state {
            color: $color-gray-900;

            &::before {
                background-color: $color-gray-900;
            }

            &::after {
                border-color: $color-white;
            }
        }
    }

    .expander__blur {
        background: linear-gradient(transparent, $color-white);
    }

    .expander__text {
        color: $color-white;
    }

    .expander__btn .btn {
        color: $color-gray-900;

        &:hover::after {
            background-color: $color-white;
        }

        &::after {
            background-color: $color-white;
        }
    }

    &.list,
    .list {
        color: $color-gray-950;
    }
    
    &.headline,
    .headline {
        color: $color-gray-900;
    }

    &.radiobox,
    .radiobox {
        &:hover .radiobox__state {
            color: $color-black-200;
        }

        &.radiobox--error .radiobox__state {
            color: $color-red-200;

            &::before {
                background-color: $color-red-150;
            }
        }

        &.radiobox--disabled .radiobox__state {
            color: $color-gray-550;

            &::before {
                background-color: $color-gray-800;
            }
        }

        .radiobox__state {
            color: $color-gray-900;

            &::before {
                background-color: $color-gray-900;
            }
        }
    }

    &.teaser,
    .teaser {
        color: $color-white;
    }

    .teaser__content {
        &::after,
        &::before {
            background-color: $color-gray-900;
        }
    }

    &.toggle,
    .toggle {
        &:hover .toggle__state::before {
            background-color: $color-gray-750;
        }

        &.toggle--disabled .toggle__state {
            &::before {
                background-color: $color-gray-500;
            }
        }

        .toggle__state {
            color: $color-black-200;

            &::before {
                background-color: $color-gray-900;
            }
        }
    }

    &.separator__line,
    .separator__line {
        background-color: $color-gray-900;
    }

    &.icon-btn,
    .icon-btn {
        background-color: $color-gray-900;

        .icon-btn__icon {
            color: $color-white;
        }
    }

    &.brick-03-media__teaser-container-inner,
    .brick-03-media__teaser-container-inner {
        background-color: $color-gray-900;
    }
}

.light-theme__background {
    background-color: $color-white;
}

@include media-breakpoint-down(sm) {
    .content-main {
        & > .pimcore_area_content > * {
            &.light-theme.brick-03:not(.brick-03--has-teaser).brick--color-sequence:after {
                background-color: $color-gray-900;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .light-theme {
        &.brick-03-media__teaser-container-inner,
        .brick-03-media__teaser-container-inner {
            background-color: transparent;
        }
    }
}
