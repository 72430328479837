.accordion {
    color: $color-white;

    background-color: $color-gray-900;
}

.accordion__headline {
    position: relative;

    padding: 17px 38px 17px 20px;

    color: $color-white;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;

    cursor: pointer;

    &.collapsed i::before {
        content: $icon-plus-big;
    }

    i {
        position: absolute;
        top: 17px;
        right: 20px;

        font-size: 24px;
    }
}

.accordion__content {
    padding: 20px;
}
