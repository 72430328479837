.slogan {
    position: absolute;
    right: 0;

    display: inline-block;
    padding-right: 30px;
}

.slogan__wrapper {
    position: fixed;
    top:  94px;
    right: 0;
    z-index: 30;

    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;

    background-color: fade-out($color-white, 0.05);

    transition: width $animation-speed, background-color $animation-speed;

    &.slogan__wrapper--docked {
        transition: width $animation-speed, background-color $animation-speed;
        box-shadow: 1px 1px 1px fade_out($color-gray-100, 0.05);
    }
}

.site-wrapper.site-wrapper--header-fade-out {
    .slogan__wrapper {
        top: 0 !important;

        transition: width $animation-speed, background-color $animation-speed, top $animation-speed;
    }
}

@media screen and (min-width: map-get($container-max-widths, sm)) {
    .slogan {
        right: calc((100vw - #{map-get($container-max-widths, sm)}) / 2);
    }
}

@include media-breakpoint-up(md) {
    .slogan {
        right: calc((100vw - #{map-get($container-max-widths, md)}) / 2);
    }
}

@include media-breakpoint-up(lg) {
    .site-wrapper {
        &--has-breadcrumb {
            .slogan__wrapper {
                background-color: transparent;
                box-shadow: none;

                &--docked {
                    width: 33%;
                }
            }
        }
    }

    .slogan__wrapper {
        height: 44px;
        width: 33%;
        transition: width $animation-speed, background-color $animation-speed;

        &--docked {
            width: 100%;
        }
    }

    .slogan {
        right: calc((100vw - #{map-get($container-max-widths, lg)}) / 2);
    }
}

@include media-breakpoint-up(xl) {
    .slogan__wrapper {
        top: 100px;
    }

    .slogan {
        right: 0;
    }
}

@media screen and (min-width: map-get($container-max-widths, xl)) {
    .slogan {
        right: calc((100vw - #{map-get($container-max-widths, xl)}) / 2);
    }
}
