.tarteaucitronAllow {
    background-color: $color-gray-900 !important;
}

#tarteaucitronPersonalize {
    background: $color-gray-900 !important;
}

#tarteaucitronCloseAlert {
    padding: 8px 10px 5px 10px !important;
    margin-bottom: 0px !important;
    font-size: 16px !important;
}

.tarteaucitronLine {
    &.tarteaucitronIsAllowed {
        border-color: $color-gray-900 !important;
    }
}

.tarteaucitronAlertSmallBottom {
    left: 0;
    right: unset;
    background: none !important;

    #tarteaucitronManager {
        width: 58px !important;
        padding: 3px 0px !important;
        text-align: center !important;
        text-decoration: none !important;
        background: rgba(0, 0, 0, 0.85) !important;

        i {
            font-family: verseidag-website-2020-iconfont !important;
            color: $color-white !important;
            font-size: 28px !important;
        }
    }
}

#tarteaucitronAlertBig {
    #tarteaucitronImprintUrl {
        font: 15px verdana;
        color: #fff;
        cursor: pointer;
    }
}

#tarteaucitron {
    #tarteaucitronImprintUrl {
        background: #fff;
        color: #333;
        font-size: 13px;
        margin-bottom: 3px;
        margin-left: 7px;
        padding: 4px 10px;
    }
}

@include media-breakpoint-up(lg) {
    .tarteaucitronAlertSmallBottom {
        right: 0;
        left: unset;

        #tarteaucitronManager {
            width: 70px !important;
            padding: 8px 0px !important;

            i {
                font-size: 38px !important;
                line-height: 52px !important;
            }
        }
    }
}
