.scroll-hint__text {
    color: $color-black-200;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
}

.scroll-hint__animation,
.scroll-hint__arrows {
    margin: 0 auto;
}

.scroll-hint__animation {
    position: relative;

    width: 25px;
    height: 38px;
    border: 3px solid $color-black;
    border-radius: 14px;

    &::before {
        position: absolute;
        left: 50%;

        display: block;

        border-radius: 100%;

        background-color: $color-black;

        transform: translateX(-50%);

        animation: scrollHintAnimation 2s ease infinite;

        content: "";
    }
}

.scroll-hint__arrows {
    position: relative;

    width: 38px;
    height: 28px;

    animation: scrollHintArrows 2s ease infinite;

    &::before,
    &::after {
        position: absolute;
        left: 50%;

        display: block;
        width: 12px;
        height: 12px;

        border-right: 2px solid $color-black;
        border-bottom: 2px solid $color-black;

        transform: translateX(-6px) rotateZ(45deg);

        content: "";
    }

    &::before {
        top: 0;
    }

    &::after {
        top: 7px;
    }
}

@keyframes scrollHintAnimation {
    0% {
        top: 5px;

        width: 0;
        height: 0;
    }

    5% {
        top: 5px;

        width: 3px;
        height: 5px;
    }

    90% {
        width: 3px;
        height: 5px;
    }

    100% {
        top: 22px;

        width: 0;
        height: 0;
    }
}

@keyframes scrollHintArrows {
    45% {
        transform: translateY(0);
    }

    60% {
        transform: translateY(5px);
    }

    75% {
        transform: translateY(0);
    }
}
