.search-content {
  margin: 145px 0 15px;

  .highlight {
    font-weight: bolder;
  }
}

.search-results .headline {
  margin-bottom: 31px;
}

.search-results .beam {
  margin-bottom: 25px;
}

.search-results .summary {
  font-size: 16px;
  font-family: $font-family-base;
  color: $color-black-200;
  font-weight: $font-weight-medium;
}

.search-result-suggestion {
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

.search-result-item .item-headline {
  font-size: 20px;
  font-family: $font-family-base;
  font-weight: $font-weight-medium;
  margin: 14px 0;
}

.search-result-item .link {
  text-decoration: underline;
  font-size: 16px;
  color: $color-black-200;
  margin-top: 5px;
}

.divider {
  height: 2px;
  background-color: $color-gray-900;
  width: 100%;
  margin: 14px 0;
}

@include media-breakpoint-up(md) {
  .search-content {
    margin: 190px 0 45px;
  }

  .search-results .headline {
    margin-bottom: 33px;
  }

  .search-results .beam {
    margin-bottom: 38px;
  }

  .search-results .summary {
    margin-bottom: 10px;
  }

  .search-result-item .item-headline {
    margin: 15px 0 5px 0;
  }

  .divider {
    margin: 15px 0 0 0;
  }
}

@include media-breakpoint-up(xl) {
  .search-content {
    margin: 220px 0 95px;
  }

  .search-results .headline {
    margin-bottom: 41px;
  }

  .search-results .beam {
    margin-bottom: 62px;
  }

  .search-results .summary {
    margin-bottom: 23px;
    font-size: 20px;
  }

  .search-result-item .item-headline {
    font-size: 24px;
    margin: 7px 0;
  }

  .search-result-item .link {
    font-size: 18px;
  }

  .divider {
    margin: 25px 0 10px 0;
  }
}
