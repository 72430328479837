.link-icon {
    position: relative;

    display: flex;
    align-items: center;

    font-family: $font-family-base;
    text-decoration: none;
}

.link-icon__text {
    padding: 0 10px;
}

.link-icon__icon {
    font-size: 20px;
}
