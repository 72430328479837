.head-slider {
    position: relative;

    .carousel-inner {
        overflow: visible;
    }
}

.head-slider__item {
    width: 100%;

    z-index: 3;

    .head-slider__item-text {
        z-index: 16;

        color: $color-white;
    }
}

.head-slider__item-image {
    color: $color-white;

    background-color: $color-gray-900;

    img {
        width: 100%;
    }
}

.head-slider__item-image-data {
    margin-top: 8px;
}

.head-slider__item-text {
    background-color: $color-gray-900;

    .teaser {
        padding: 15px 0 30px;
    }

    .teaser__headline {
        margin-bottom: 0;

        & + .teaser__copy,
        & + .teaser__btn {
            margin-top: 15px;
        }
    }

    .teaser__copy {
        margin-bottom: 0;

        & + .teaser__btn {
            margin-top: 25px;
        }
    }

    .teaser__btn {
        display: block;

        text-align: center;
    }
}

.head-slider__dots {
    top: calc(100vw * 3 / 4 - 15px);
}

.head-slider__control {
    bottom: auto;
    z-index: 5;

    display: none;
    width: 50px;

    color: $color-white;

    font-size: 28px;

    opacity: 1;
}

.head-slider__dots {
    bottom: auto;

    margin: -23px 0 0;
}

.head-slider__dot {
    width: 35px;
    margin-right: 9px;

    background-color: $color-white;
}

.head-slider__dot-inner {
    width: 100%;
    height: 3px;

    box-shadow: 0 0 5px fade_out($color-black, 0.7);
}

.head-slider__dot--active {
    background-color: $color-gray-900;
}

.head-slider__scroll {
    background-color: $color-white;
}

.head-slider__scroll-hint {
    display: none;
}

@include media-breakpoint-up(md) {
    .head-slider__dots {
        top: calc(100vw * 9 / 21 - 10px);
    }
}

@include media-breakpoint-up(lg) {
    .head-slider {
        padding-bottom: 150px;
    }

    .head-slider__control--next,
    .head-slider__control--prev {
        transition: color $animation-speed;

        &:hover {
            color: $color-gray-900;
        }
    }

    .head-slider__item-image-data {
        position: absolute;
        width: 100%;

        .image__subline,
        .image__copyright {
            width: 60%;
        }
    }

    .head-slider__item {
        transition: $animation-speed * 3 transform;
        transition-delay: $animation-speed / 2;

        z-index: 3;

        &.has-text {
            .head-slider__item-image {
                .container {
                    padding-top: 50px;
                }
            }
        }

        .head-slider__item-text {
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 16;

            opacity: 0;

            transition: opacity $animation-speed * 1.5;
        }

        .head-slider__item-image .container {
            transition: opacity $animation-speed * 1.5;
        }

        &.active {
            .head-slider__item-text,
            .head-slider__item-image .container {
                opacity: 1;
            }
        }

        &.carousel-item-left,
        &.carousel-item-right {
            .head-slider__item-text,
            .head-slider__item-image .container {
                opacity: 0;
            }
        }

        &.carousel-item-next,
        &.carousel-item-prev {
            z-index: 1;

            transition: $animation-speed * 3 transform;
        }
    }

    .head-slider__item-image {
        color: $color-black-200;

        background-color: $color-white;
    }

    .head-slider__item-text {
        position: absolute;
        bottom: 0;

        width: 100%;

        background: transparent;

        .teaser {
            position: absolute;
            bottom: -50px;
            z-index: 20;

            width: 60%;
            max-height: calc(100vw * 9 / 21 - 100px);

            padding: 40px;
        }

        .teaser__btn {
            display: inline-block;
        }
    }

    .head-slider__dots {
        top: calc(100vw * 9 / 21 - 10px);

        margin-left: 60%;
    }

    .head-slider__control {
        top: calc(100vw * 9 / 21 - 45px);
        text-shadow: 0 0 10px fade-out($color-black, 0.7);

        display: flex;
    }
}

@include media-breakpoint-up(xl) {
    .head-slider__scroll-hint {
        position: absolute;
        top: calc(100vw * 9/21 + 35px);
        left: 0;
        right: 0;
        z-index: 17;

        display: block;
    }

    .head-slider__dots {
        padding-left: 0;
        width: 300px;
        margin: -23px auto 0 auto;
    }

    .head-slider__item-text .teaser {
        width: 34.375%;
    }

    .head-slider__item-image-data {
        .image__subline,
        .image__copyright {
            width: 34.375%;
        }
    }

    .head-slider__control {
        top: calc(100vw * 9 / 21 / 2 - 14px);

        font-size: 45px;
    }
}

// @toDo maximalweite von 1920px sollte später noch über eine Variable festgesetzt werden.
@media screen and (min-width: 1920px) {
    .head-slider__scroll-hint {
        top: calc(1920px * 9/21 + 20px);
    }

    .head-slider__dots {
        top: calc(1920px * 9 / 21 - 10px);
    }

    .head-slider__control {
        top: calc(1920px * 9 / 21 / 2 - 14px);
    }
}
