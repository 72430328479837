.checkbox {
    position: relative;

    input {
        position: absolute;
        bottom: 0;
        z-index: 1;

        width: 20px;
        height: 20px;

        cursor: pointer;
        opacity: 0;
    }

    input:checked + .checkbox__state::after {
        content: "";
    }

    input:disabled {
        cursor: default;

        + .checkbox__state {
            color: $color-gray-550;
        }
    }

    .checkbox__state {
        position: relative;

        margin: 0;
        padding-left: 30px;

        font-weight: 400;
        font-size: 18px;
        line-height: 24px;

        &::before {
            position: absolute;
            bottom: 0;
            left: 0;

            width: 20px;
            height: 20px;

            background-color: $color-gray-500;

            content: "";
        }

        &::after {
            position: absolute;
            bottom: 7px;
            left: 7px;

            width: 6px;
            height: 9px;

            border-right: 1px solid $color-gray-900;
            border-bottom: 1px solid $color-gray-900;

            transform: rotateZ(45deg);
        }
    }
}
