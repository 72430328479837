@media print {
    body {
        color: black !important;
    }

    a {
        page-break-inside: avoid
    }

    blockquote {
        page-break-inside: avoid;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        page-break-after: avoid;
        page-break-inside: avoid
    }

    img {
        page-break-inside: avoid;
        page-break-after: avoid;
    }

    a:link, a:visited, a {
        background: transparent;
        color: $color-gray-700;
        font-weight: bold;
        text-decoration: underline;
        text-align: left;
    }

    a {
        page-break-inside:avoid
    }

    a[href^=http]:after {
        content:" < " attr(href) "> ";
    }

    article a[href^="#"]:after {
        content: "";
    }

    a:not(:local-link):after {
        content:" < " attr(href) "> ";
    }

    table,
    pre {
        page-break-inside: avoid
    }

    .entry iframe {
        display: none;
        width: 0 !important;
        height: 0 !important;
        overflow: hidden !important;
        line-height: 0pt !important;
        white-space: nowrap;
    }

    .embed-youtube,
    .embed-responsive {
        position: absolute;
        height: 0;
        overflow: hidden;
    }

    .header {
        display: none;
    }

    .header__logo {
        &--print {
            display: block;
            padding-bottom: 100px;
        }
    }

    .breadcrumb {
        display: none;
    }

    .slogan__wrapper {
        display: none;
    }

    .service-badge {
        display: none;
    }

    .footer {
        display: none;
    }

    .aos-init {
        opacity: 1 !important;
        transform: translate(0, 0) !important;
    }

    .btn__wrapper,
    .btn {
        display: none;
    }

    .gallery .pager__item--active .image-teaser--passive-effect::after {
        display: none;
    }

    [class*="col-"] {
        margin: 10px 0;
        display: block !important;
        max-width: 100% !important;
        width: 100% !important;
    }

    .row {
        display: block;
    }
}
