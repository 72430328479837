.pager {
    position: relative;

    width: 100%;
}

.pager__control {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;

    width: 55px;

    font-size: 47px;
    color: $color-gray-900;

    cursor: pointer;

    transition: background-color $animation-speed, color $animation-speed;

    &:hover {
        color: $color-white;

        background-color: $color-gray-900;
    }

    &--left {
        left: 0;
    }

    &--right {
        right: 0;
    }
}

.pager__slot {
    width: calc(100% - 120px);
    margin: auto;
    overflow: hidden;
}

.pager__item-list {
    position: relative;

    display: block;
    white-space: nowrap;
    width: auto;
    height: 70px;

    transform: translateX(0);
    transition: transform 0.33s;
}

.pager__item {
    display: inline-block;
    max-height: 150px;
    margin: 0 5px;

    cursor: pointer;

    white-space: normal;

    img {
        height: 70px;
        width: auto;
        max-height: 100%;
        max-width: 100%;
    }
}

@include media-breakpoint-up(xl) {
    .pager__control {
        width: 80px;
    }

    .pager__item-list {
        height: 110px;
    }

    .pager__item {
        img {
            height: 110px;
        }
    }

    .pager__slot {
        width: calc(100% - 170px);
    }
}