.gallery {
    .pager__item--active .image-teaser--passive-effect {
        filter: grayscale(0);

        transition-duration: $animation-speed * 3;

        &::after {
            opacity: 0;
        }

        .image-teaser__label {
            color: $color-white;

            background-color: fade_out($color-black, 0.4);
        }
    }

    .pager__item-list {
        transition-duration: $animation-speed * 3;
    }

    .carousel-control-prev,
    .carousel-control-next {
        font-size: 38px;
        color: $color-gray-900;
        z-index: 10;

        opacity: 1;

        i {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 55px;
            height: 70px;

            background-color: fade_out($color-white, 0.2);

            transition: color $animation-speed, background-color $animation-speed;

            &:hover {
                color: $color-white;
                background-color: $color-gray-900;
            }
        }
    }

    .carousel-control-prev {
        justify-content: flex-start;
    }

    .carousel-control-next {
        justify-content: flex-end;
    }

    .head-slider__item-image-data {
        position: absolute;
        width: 100%;

        .image__subline,
        .image__copyright {
            width: 60%;
        }
    }

    .carousel-item {
        transition: $animation-speed * 3 transform;
        transition-delay: $animation-speed / 2;

        z-index: 3;

        &.carousel-item-next,
        &.carousel-item-prev {
            z-index: 1;

            transition: $animation-speed * 3 transform;
        }
    }
}

.gallery__carousel,
.gallery__img-container {
    height: 211px;
}

.gallery__img-container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    img {
        max-height: 100%;
        max-width: 100%;
    }
}

.gallery__pager {
    margin-top: 10px;
}

.gallery__copyright {
    padding: 5px 20px;
    min-height: 40px;
}

@include media-breakpoint-up(md) {
    .gallery__carousel,
    .gallery__img-container {
        height: 400px;
    }

    .gallery__img-container {
        width: 100%;

        img {
            max-height: 100%;
            max-width: 100%;
        }
    }

    .gallery {
        .carousel-control-prev,
        .carousel-control-next {
            font-size: 47px;

            i {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 55px;
                height: 70px;

                background-color: fade_out($color-white, 0.2);

                transition: color $animation-speed, background-color $animation-speed;

                &:hover {
                    color: $color-white;
                    background-color: $color-gray-900;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .gallery__carousel,
    .gallery__img-container {
        height: 686px;
    }

    .gallery__img-container {
        width: 100%;

        img {
            width: auto;
            max-height: 100%;
            max-width: 100%;
        }
    }

    .gallery {
        .carousel-control-prev,
        .carousel-control-next {
            i {
                width: 80px;
                height: 110px;
            }
        }
    }
}
