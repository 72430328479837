.carousel-indicators li {
  width: 35px;

  opacity: 1;

  background-color: $color-white;

  transition: background-color $animation-speed;

  &.active {
    background-color: $color-gray-900;
  }
}

@include media-breakpoint-up(xl) {
  .carousel-indicators li {
    width: 50px;
  }
}