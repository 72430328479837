.expander {
    position: absolute;
    left: 0;
    z-index: 2;

    width: 100%;

    text-align: center;

    &.expander--collapsed {
        .expander__blur {
            margin-bottom: 56px;

            opacity: 1;
        }

        .expander__btn .icon-arrow-up-big {
            transform: rotateZ(180deg);
        }

        .expander__text {
            .text-open {
                display: none;
            }

            .text-closed {
                display: inline;
            }
        }
    }
}

.expander-target {
    transition: $animation-speed ease height;
}

.expander__blur {
    width: 100%;
    height: 56px;
    margin-bottom: 0;

    opacity: 0;

    transition: $animation-speed ease opacity;
}

.expander__btn {
    width: 50px;

    .btn {
        padding: 9px 0;
    }

    .icon-arrow-up-big {
        display: inline-block;

        font-size: 28px;
        line-height: 20px;
    }
}

.expander__text {
    margin-top: 3px;

    .text-closed {
        display: none;
    }
}

@include media-breakpoint-down(md) {
    .expander-target--collapsed {
        height: 56px;

        overflow: hidden;

        .expander__blur {
            opacity: 1;
        }
    }
}

@include media-breakpoint-up(md) {
    .expander__blur {
        display: none;
    }
}
