.btn__wrapper {
    position: relative;

    display: inline-block;

    overflow: hidden;
}

.btn {
    padding: 9px 30px;

    color: $color-white;
    font-size: 20px;

    transition: color $animation-speed;
}

.btn__text {
    position: relative;
    z-index: 3;
}

.btn::before {
    position: absolute;
    top: 0;
    right: 30px;
    bottom: 0;
    left: 0;
    z-index: 1;

    display: block;

    background-color: $color-gray-500;

    transition: background-color $animation-speed * 2;

    content: "";
}

.btn::after {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    display: block;
    width: 300px;
    height: 300px;

    background-color: $color-gray-500;
    transform: rotate(-31deg) translateX(65px);

    transition: background-color $animation-speed * 2, transform $animation-speed * 2;

    content: "";
}

.btn__wrapper:not(.btn__wrapper--disabled):hover,
.btn__wrapper--active {
    .btn {
        color: $color-gray-925;
    }

    .btn::before {
        background-color: $color-gray-400;
    }

    .btn::after {
        background-color: $color-gray-400;

        transform: rotate(-31deg) translateX(45px);
    }
}

.btn__wrapper--disabled {
    .btn {
        color: $color-gray-550;
    }

    .btn::before {
        background-color: $color-gray-800;
    }

    .btn::after {
        background-color: $color-gray-800;
    }
}

.icon-btn {
    display: inline-block;

    padding: 11px;
    border: 0;

    font-size: 29px;

    cursor: pointer;

    transition: background-color $animation-speed * 2;

    &:hover {
        background-color: $color-gray-500;
    }
}


