.is-ie {
    // Slogan fix
    .slogan__wrapper {
        width: 100%;
    }

    .slogan {
        top: 10px;
    }

    @include media-breakpoint-up(md) {
        .slogan__wrapper {
            width: 100%;
        }
    }
}
