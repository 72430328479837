.teaser {
    position: relative;

    display: flex;
    padding: 30px 40px 30px 46px;
    overflow: hidden;

    color: $color-white;
}

.teaser__headline {
    margin-bottom: 15px;

    p:last-child {
        margin-bottom: 0;
    }
}

.teaser__copy {
    margin-bottom: 25px;

    p:last-child {
        margin-bottom: 0;
    }
}

.teaser__inner-content {
    position: relative;
    z-index: 3;
}

.teaser__content {
    &::before {
        position: absolute;
        top: 0;
        right: 46px;
        bottom: 0;
        left: 0;
        z-index: 1;

        display: block;

        background-color: $color-gray-900;

        content: "";
    }

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        display: block;
        width: 2600px;
        height: 2600px;

        background-color: $color-gray-900;

        transform: rotate(-31deg) translateX(445px);

        content: "";
    }
}
