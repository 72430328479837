.text-combination {
    .btn__wrapper {
        width: 100%;
        margin-top: 20px;

        text-align: center;
    }

    .expander__btn {
        margin-top: 0;
    }
}

.text-combination__copy {
    position: relative;

    margin-top: 45px;

    .expander__blur {
        position: absolute;
        bottom: 0;
    }
}

@include media-breakpoint-down(md) {
    .text-combination {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

@include media-breakpoint-up(md) {
    .text-combination {
        .btn__wrapper {
            width: auto;
        }
    }

    .text-combination__copy {
        &--columns {
            column-count: 2;
            column-gap: 30px;
        }
    }
}
