// Headings H1
.headline {
    font-weight: $font-weight-medium;
    font-size: 24px;
    font-family: $font-family-base;
    line-height: 34px;
    margin-bottom: 12px;
}

@include media-breakpoint-up(md) {
    .headline {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px;
    }
}

@include media-breakpoint-up(xl) {
    .headline {
        font-size: 42px;
        line-height: 54px;
        margin-bottom: 40px;
    }
}
