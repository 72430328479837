.submenu {
    padding: 20px;

    overflow-x: auto;

    &--background-70 {
        background-color: fade_out($color-black, 0.3);
    }

    &--background-60 {
        background-color: fade_out($color-black, 0.4);
    }
}

.submenu__navigation-list  {
    padding: 0;

    list-style: none;
}

.submenu__navigation-link {
    display: block;
    padding: 10px;

    color: $color-white;

    opacity: 0.7;

    transition: opacity $animation-speed;

    &:hover,
    .submenu__navigation-link--active {
        color: $color-white;

        opacity: 1;
    }
}
