.mega-menu__container {
    height: 0;
    max-width: 1920px;
    overflow: hidden;
}

@include media-breakpoint-up(xl) {
    .mega-menu__container {
        position: fixed;
        top: 100px;
        z-index: 50;

        height: 0;
        width: 100vw;

        background-color: transparent;

        transition: height $animation-speed * 3, background-color $animation-speed * 3;

        &.js-nested-menu--active {
            height: calc(100vh - 100px);

            background-color: fade_out($color-black, 0.43);

            transition: height $animation-speed * 3;
        }
    }

    .mega-menu {
        position: absolute;
        height: 0;
        width: 100%;
        overflow: hidden;

        background-color: fade_out($color-black, 0.3);

        transition: height $animation-speed * 2;

        .submenu__navigation-link.js-nested-menu--active {
            opacity: 1;
        }

        .submenu:first-of-type {
            opacity: 0;

            transition: opacity $animation-speed * 2;
        }

        &.js-nested-menu--active {
            height: 610px;

            .submenu:first-of-type {
                opacity: 1;

                transition-delay: $animation-speed * 2;
            }
        }
    }

    .mega-menu__content-container {
        position: relative;

        max-width: map-get($container-max-widths, xl) + $grid-gutter-width / 2;
        padding: 0;
    }

    .mega-menu__background-images {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 1 / $mega-menu-columns * 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .mega-menu__background-image {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        height: 610px;

        opacity: 0;

        transition: opacity $animation-speed * 2;

        &.js-nested-menu--active {
            z-index: 2;

            opacity: 1;
        }
    }

    .mega-menu__submenu-container {
        position: relative;
        z-index: 5;

        display: flex;
        height: 610px;

        .submenu {
            position: relative;
            top: 0;
            bottom: 0;

            width: 0;
            height: 610px;
            padding: 20px 0;

            overflow: auto;

            transition: width $animation-speed * 2, padding $animation-speed * 2;

            .simplebar-track.simplebar-vertical {
                top: 30px;
                bottom: 30px;
            }

            &--active,
            &.js-nested-menu--active {
                width: 1 / $mega-menu-columns * 100%;
                padding: 20px 20px;
            }
        }

        .submenu__navigation {
            witdh: 100%;
            min-width: 200px;
        }
    }

    .mega-menu__teaser-container {
        position: relative;
        top: 0;
        bottom: 0;

        width: 2 / $mega-menu-columns * 100%;
    }

    .mega-menu__teaser {
        position: absolute;
        z-index: 2;

        right: 60px;
        bottom: 60px;
        left: 60px;

        opacity: 0;

        transition: opacity $animation-speed * 2;

        &.js-nested-menu--active {
            z-index: 3;

            opacity: 1;
        }
    }

    .js-nested-menu--active {
        display: block;
    }
}
