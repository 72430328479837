.brick-03 .brick-03-media {
    &:first-child {
        padding-top: 0;

        &.brick-03-media__content-container {
            padding-bottom: 0;
        }
    }
}

.brick-03-media {
    position: relative;

    .gallery__carousel,
    .gallery__img-container {
        height: 0;
        padding-bottom: 9 / 16 * 100%;
    }

    .gallery__img-container,
    .gallery__carousel {
        &--21-9 {
            padding-bottom: 9 / 21 * 100%;
        }

        &--16-9 {
            padding-bottom: 9 / 16 * 100%;
        }

        &--4-3 {
            padding-bottom: 3 / 4 * 100%;
        }

        &--1-1 {
            padding-bottom: 1 / 1 * 100%;
        }

        &--3-4 {
            padding-bottom: 4 / 3 * 100%;
        }

        &--1-2 {
            padding-bottom: 2 / 1 * 100%;
        }
    }

    .gallery__img-container {
        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
        }
    }
}

.brick-03-media__content-container {
    position: relative;

    padding-top: 30px;
    padding-bottom: 30px;
}

.brick-03-media__body {
    .copy {
        position:relative;
        margin-top: 5px;

        .expander__blur {
            position: absolute;
            bottom: 0;
        }
    }
}

.brick-03-media__image {
    margin-top: 15px;
    width: 100%;
}

.brick-03-media__link {
    display: block;
    width: 100%;
    margin-top: 10px;

    text-align: center;
}

.brick-03-media__link--error-page {
    margin-top: 40px;
}

.brick-03-media__expander {
    bottom: -59px;

    .expander__btn {
        margin-top: 60px;
    }
}

.brick-03-media__teaser-container {
    padding: 0;

    border-bottom: 2px solid transparent;

    .teaser {
        padding: 60px 25px 25px;
    }

    .teaser__headline {
        margin-bottom: 12px;
    }

    .teaser__copy {
        margin-bottom: 0;

        ol,
        ul {
            padding-left: 20px;
        }
    }
}

.brick-03-media__text ul {
    display: table;
}

@include media-breakpoint-up(md) {
    .brick-03-media {
        &.brick-03-media--image-right {
            .brick-03-media__image {
                float: right;

                padding: 0 0 10px 20px;
            }

            &.brick-03-media--image-landscape {
                .brick-03-media__text {
                    margin: 0 41.66667% 0 0;
                }
            }

            &.brick-03-media--image-portrait {
                .brick-03-media__text {
                    margin: 0 20% 0 0;
                }
            }
        }

        &.brick-03-media--image-landscape {
            .brick-03-media__text {
                margin: 0 0 0 41.66667%;
            }

            .brick-03-media__image {
                width: 58.33333%;
            }
        }

        &.brick-03-media--image-portrait {
            .brick-03-media__text {
                margin: 0 0 0 16.66667%;
            }

            .brick-03-media__image {
                width: 31.66667%;
            }
        }

        &.brick-03-media--has-teaser {
            &.brick-03-media--image-right {
                &.brick-03-media--image-landscape {
                    .brick-03-media__teaser-container .brick-03-media__teaser-container-inner {
                        margin: 0 58.33333% 0 0;
                    }

                    .brick-03-media__image,
                    .brick-03-media__text {
                        margin: 0 0 0 41.66667%;
                        padding: 0 0 0 20px;
                    }

                    .brick-03-media__image {
                        padding-bottom: 10px;
                    }
                }

                &.brick-03-media--image-portrait {
                    .brick-03-media__teaser-container .brick-03-media__teaser-container-inner {
                        margin: 0 41.66667% 0 0;
                    }

                    .brick-03-media__image,
                    .brick-03-media__text {
                        margin: 0 0 0 58.33333%;
                        padding: 0 0 0 20px;
                    }
                }
            }

            &.brick-03-media--image-landscape {
                .brick-03-media__teaser-container .brick-03-media__teaser-container-inner {
                    margin: 0 0 0 58.33333%;
                }

                .brick-03-media__image,
                .brick-03-media__text {
                    margin: 0 41.66667% 0 0;
                    padding: 0 20px 0 0;
                }

                .brick-03-media__image {
                    padding-bottom: 10px;
                }
            }

            &.brick-03-media--image-portrait {
                .brick-03-media__teaser-container .brick-03-media__teaser-container-inner {
                    margin: 0 0 0 41.66667%;
                }

                .brick-03-media__image,
                .brick-03-media__text {
                    margin: 0 58.33333% 0 0;
                    padding: 0 20px 0 0;
                }

                .brick-03-media__image {
                    padding-bottom: 10px;
                }
            }

            .brick-03-media__image {
                float: none;

                width: auto;
                margin-top: 0;
            }

            .brick-03-media__text {
                width: auto;
                margin: 0;
            }
        }
    }

    .brick-03-media__body {
        margin-top: 32px;
        display: block;

        &::after {
            display: block;

            clear: both;

            content: "";
        }
    }

    .brick-03-media__image {
        float: left;
        shape-outside: inset(88px 0 0 0);

        margin-top: 88px;
        padding: 20px 20px 10px 0;
    }

    .brick-03-media__link {
        display: inline-block;

        width: auto;
    }

    .brick-03-media__link--error-page {
        margin-top: 30px;
    }

    .brick-03-media__expander {
        display: none;
    }

    .brick-03-media__teaser-container {
        position: absolute;
        top: 62px;
        right: 0;
        left: 0;

        padding: 0 $grid-gutter-width / 2;

        .teaser {
            padding-top: 20px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .brick-03-media {
        &.brick-03-media--image-landscape {
            .brick-03-media__text {
                margin: 0 0 0 35.6666%;
            }

            .brick-03-media__image {
                width: 64.3333%;
            }
        }

        &.brick-03-media--image-right {
            .brick-03-media__image {
                float: right;

                padding: 20px 0 10px 30px;
            }
        }
    }

    .brick-03-media__image {
        padding: 0 30px 10px 0;
    }

    .brick-03-media {
        &.brick-03-media--has-teaser {
            .brick-03-media__teaser-container {
                .teaser__copy {
                    ol,
                    ul {
                        margin-left: 32px;
                    }
                }
            }

            &.brick-03-media--image-right {
                &.brick-03-media--image-landscape {
                    .brick-03-media__teaser-container .brick-03-media__teaser-container-inner {
                        margin: 0 68.1181% 0 0;
                    }

                    .brick-03-media__image,
                    .brick-03-media__text {
                        padding: 0 0 0 $grid-gutter-width;
                    }

                    .brick-03-media__image {
                        padding-bottom: 10px;
                        margin: 32px 0 0 31.8819%;
                    }

                    .brick-03-media__text {
                        margin: 0 16.66667% 0 31.8819%;
                    }
                }

                &.brick-03-media--image-portrait {
                    .brick-03-media__teaser-container .brick-03-media__teaser-container-inner {
                        margin: 0 68.1181% 0 0;
                    }

                    .brick-03-media__image {
                        width: 36.1875%;
                    }

                    .brick-03-media__image,
                    .brick-03-media__text {
                        padding: 0 0 0 $grid-gutter-width;
                    }

                    .brick-03-media__body {
                        margin: 0 0 0 31.8819%;
                    }

                    .brick-03-media__text {
                        margin: 25px 16.66667% 0 0;
                    }

                    .brick-03-media__image {
                        float: right;
                        margin: 100px 0 0;
                    }
                }

                .brick-03-media__image {
                    float: none;
                }
            }

            &.brick-03-media--image-landscape {
                .brick-03-media__teaser-container .brick-03-media__teaser-container-inner {
                    margin: 0 0 0 68.1181%;
                }

                .brick-03-media__image,
                .brick-03-media__text {
                    padding: 0 $grid-gutter-width 0 0;
                }

                .brick-03-media__image {
                    width: auto;
                    margin: 0 31.8819% 0 0;
                }

                .brick-03-media__text {
                    margin: 0 31.8819% 0 16.66667%;
                }
            }

            &.brick-03-media--image-portrait {
                .brick-03-media__teaser-container .brick-03-media__teaser-container-inner {
                    margin: 0 0 0 68.1181%;
                }

                .brick-03-media__body {
                    margin: 32px 31.8819% 0 0;
                }

                .brick-03-media__image,
                .brick-03-media__text {
                    padding: 0 $grid-gutter-width 0 0;
                }

                .brick-03-media__text {
                    margin: 0 0 0 16.66667%;
                }

                .brick-03-media__image {
                    float: left;
                    width: 36.1875%;
                    margin: 100px 0 0;
                }
            }
        }
    }

    .brick-03-media__image {
        shape-outside: inset(98px 0 0 0);

        margin-top: 100px;
    }
}
