$font-family-base: "Fira Sans", Sans-Serif !default;

$font-weight-medium: 500 !default;

// @toDo noch nicht final, muss im Laufe des Projektes glatt gezogen werden
$container-max-widths: (
    sm: 570px,
    md: 750px,
    lg: 990px,
    xl: 1660px
) !default;
