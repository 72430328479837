.mobile-navigation {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100vh;
    max-width: 375px;

    color: fade_out($color-white, 0.3);
    font-size: 18px;
    font-family: $font-family-base;

    background-color: fade_out($color-black, 0.43);

    transform: translateY(-100vh);

    transition: transform $animation-speed * 2;

    &--collapsed {
        transform: translateY(0);
    }
}

.mobile-navigation__controls {
    display: flex;

    padding: 27px 20px 27px 30px;
}

.mobile-navigation__navigation-list {
    margin: 0;
    padding: 0;

    list-style: none;
}

.js-mobile-navigation__back-button {
    display: none;

    opacity: 0;

    transition: opacity $animation-speed;
}

.js-mobile-navigation__back-button--active {
    display: flex;
}

.js-mobile-navigation__back-button--fade {
    opacity: 1;
}

.js-mobile-navigation__close-button {
    margin-left: auto;
}

.mobile-navigation__navigation-wrapper {
    // Controls im orberen Bereich und Language dropdown im unteren Bereich werden hier abgezogen.
    height: calc(100vh - 81px - 67px);

    overflow: auto;
}

.mobile-navigation__slide {
    position: absolute;
    top: 0;

    display: none;

    width: 100%;

    transition: transform $animation-speed;
}

.js-mobile-navigation__slide--active {
    position: relative;

    display: block;

    transform: translateX(0);
}

.js-mobile-navigation__slide--fade-out {
    display: block;

    transform: translateX(-500px);
}

.js-mobile-navigation__slide--fade-in {
    display: block;

    transform: translateX(500px);
}

.mobile-navigation__nav-item-wrapper--main + .mobile-navigation__nav-item-wrapper--meta {
    border-top: 1px solid $color-white;
}

.mobile-navigation__nav-item {
    padding: 15px 20px 15px 30px;

    border-bottom: 1px solid fade_out($color-white, 0.3);

    &--active {
        color: $color-white;

        &:hover {
            color: $color-white;
        }
    }

    .link-icon__icon {
        margin-left: auto;
    }
}

.mobile-navigation__footer-controls {
    padding: 20px 30px;
}

.mobile-navigation__slide--sub {
    .mobile-navigation__nav-item {
        padding-left: 39px;

        &--main {
            padding-left: 20px;
        }
    }
}

@include media-breakpoint-up(sm) {
    .mobile-navigation__holder {
        position: relative;
    }

    .mobile-navigation {
        top: 20px;
        bottom: auto;
        left: -20px;

        height: auto;
        width: 375px;

        background-color: fade_out($color-black, 0.2);
    }

    .mobile-navigation__navigation-wrapper {
        height: auto;
        max-height: calc(100vh - 50px - 58px - 77px);
    }

    .mobile-navigation__controls {
        padding: 15px 27px;

        background-color: fade_out($color-black, 0.1);
    }
}
