.link-list .link-list__item {
    padding: 17px 20px 17px 35px;

    border-top: 2px solid $color-gray-900;

    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    list-style: none;

    &:first-child {
        border-top: 0;
    }
}
@include media-breakpoint-up(xl) {
    .link-list .link-list__item {
        display: inline-block;
        padding: 0;

        border-top: 0;

        a {
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;

            opacity: 0.8;
        }

        &::after {
            display: inline;

            opacity: 0.8;

            transition: $animation-speed ease opacity;

            content: " |";
        }

        &:last-child::after {
            content: "";
        }

        &:hover a {
            opacity: 1;
        }
    }
}
