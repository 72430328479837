.google-maps {
    background: $color-white;

    &.google-maps--dark {
        background: $color-gray-900;
    }

    &:fullscreen {
        .google-maps__control {
            position: fixed;
        }

        .google-maps__instance {
            position: fixed !important;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
        }
    }

    .google-maps__container {
        position: relative;
    }

    .google-maps__instance {
        width: 100%;
        height: 472px;

        filter: grayscale(1);
    }

    .google-maps__placecard {
        position: absolute;
        top: 15px;
        right: 10px;
        left: 10px;
    }

    .google-maps__control {
        position: absolute;

        width: 50px;
        height: 50px;

        border: 0;

        color: $color-white;

        background-color: rgba(0, 0, 0, 0.57);

        i {
            font-size: 33px;
            line-height: 50px;
        }

        &.control--zoom-in {
            bottom: 80px;
            left: 20px;
        }

        &.control--zoom-out {
            bottom: 20px;
            left: 20px;
        }

        &.control--fullscreen {
            top: 20px;
            right: 20px;

            display: none;
        }
    }
}

@include media-breakpoint-up(xl) {
    .google-maps {
        .google-maps__instance {
            height: 565px;
        }

        .google-maps__placecard {
            left: 20px;
        }

        .address__headline,
        .address__link,
        .directions__link {
            font-size: 14px;
        }

        .google-maps__control {
            &.control--fullscreen {
                display: block;
            }
        }
    }
}
