.copy {
    font-weight: $font-weight-light;
    font-size: 16px;
    font-family: $font-family-base;
    line-height: 28px;

    &--bold {
        font-weight: $font-weight-bold;
        line-height: 26px;
    }
}

@include media-breakpoint-up(md) {
    .copy {
        line-height: 26px;
    }
}

@include media-breakpoint-up(xl) {
    .copy,
    &.copy--bold {
        font-size: 18px;
        line-height: 30px;
    }
}
